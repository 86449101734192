import React, { useEffect, useState, useCallback } from "react"
import _ from "lodash"

import axios from "axios"
import Col from "react-bootstrap/Col"
import Spinner from "react-bootstrap/Spinner"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import FormControl from "react-bootstrap/FormControl"
import InputGroup from "react-bootstrap/InputGroup"

import Common from "../services/common"
import { isBrowser } from "../services/developer_mode"

//import your fontawesome icons here
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faLink, faTrash, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"

var nextExtLinkId = 0

const ExternalLinksToDownload = (props) => {
  const DefaultHeader = isBrowser() ? { authorization: "Token " + localStorage.getItem("student_authenticity_token") } : ""
  const login_credentials = DefaultHeader.authorization !== "Token null" ? DefaultHeader : ""

  const [linksData, setLinksData] = useState([])
  const [addMoreLinks, setAddMoreLinks] = useState(false)
  const [linksDataNextURL, setLinksDataNextURL] = useState(null)
  const [linksDataLoading, setLinksDataLoading] = useState({})
  const [linksDataFocusElem, setLinksDataFocusElem] = useState("")
  const [allLinksDataLoading, setAllLinksDataLoading] = useState(false)

  useEffect(() => {
    if (props.templateId > 0) fetchExtLinks(props.templateId)
  }, [props.templateId])

  useEffect(() => {
    if (addMoreLinks && linksDataFocusElem) {
      let e = document.getElementById(linksDataFocusElem)
      if (e && "focus" in e) e.focus()
    }
  }, [addMoreLinks])

  useEffect(() => {
    if (linksDataFocusElem) {
      let e = document.getElementById(linksDataFocusElem)
      if (e) {
        if ("scrollIntoView" in e) {
          e.scrollIntoView(true, { behavior: "smooth", block: "end", inline: "nearest" })
        } else {
          window.scrollTo({ top: e.offsetTop, behavior: "smooth" })
        }
      }
    }
  }, [linksDataFocusElem])

  const updateLessonSessionUrl = () => {
    props.setActiveSection("Home")
    if (props.fetchRecentActivity) {
      props.fetchRecentActivity("true", "external_link")
    }
    window.history.replaceState(null, null, `${window.location.origin}${window.location.pathname}`)
  }

  function fetchExtLinksNext() {
    if (linksDataNextURL == null) return
    axios.get(linksDataNextURL.replace("http:", process.env.GATSBY_REACT_APP_API_HOSTPROTOCAL), { headers: login_credentials }).then((res) => {
      if (res.data["results"]?.length > 0) {
        setLinksDataNextURL(res.data.next)
        setLinksData((s) => [...s, ...res.data.results])
        setLinksDataFocusElem(`link_${linksData.length}`)
      }
    })
  }

  function getNextExtLinksOnScroll() {
    if (linksDataNextURL !== null) {
      let ele = document.getElementById("divExtLinks")
      if (ele) {
        if (ele.scrollTop + ele.offsetHeight >= ele.scrollHeight) fetchExtLinksNext()
      }
    }
  }

  function fetchExtLinks(templateId) {
    axios
      .get(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/?defaultcoursetemplate=${templateId}&ordering=id`, { headers: login_credentials })
      .then((res) => {
        if (res.data["results"]?.length > 0) {
          setLinksDataNextURL(res.data.next)
          setLinksData(res.data["results"])
        } else {
          setLinksData([{ id: `el-${nextExtLinkId++}`, link: "", name: "", add_to_template: templateId, icon: null, notInDB: true }])
        }
      })
  }

  const delayedSaveExtLink = useCallback(
    _.debounce((index, value, pLinksData) => {
      handleLinkSave(index, value, pLinksData)
    }, 1500),
    []
  )

  function handleLinkSave(index, value, obj) {
    if (!obj[index]["notInDB"]) {
      return
    }

    let v = value
    if (v.indexOf("http://") == -1 && v.indexOf("https://") == -1) {
      v = "https://" + value
    }

    let pLinksData = [...obj]
    if (!Common.isValidURL(v)) {
      pLinksData[index].error = "Enter a valid URL"
      setLinksData([...pLinksData])
      return
    }

    pLinksData[index]["link"] = v
    pLinksData[index]["name"] = "Link"
    //value = v

    let data = { name: pLinksData[index].name, link: v, add_to_template: pLinksData[index].add_to_template }

    axios
      .post(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/create/`, data, { headers: login_credentials })
      .then((res) => {
        if (res.data.link === "Link") {
          pLinksData[index].error = "Enter a valid URL"
        } else {
          pLinksData[index].id = res.data.id
          pLinksData[index].name = res.data.name
          pLinksData[index].icon = res.data.icon
          pLinksData[index].error = ""
          delete pLinksData[index]["notInDB"]
        }
        setLinksData([...pLinksData])
        setAddMoreLinks(false)
      })
      .catch((err) => {
        if (err.response.data.link?.length > 0) {
          pLinksData[index].error = err.response.data.link[0]
        }
        setLinksData([...pLinksData])
      })
  }

  function handleAddMoreLinks(e) {
    e.preventDefault()
    setLinksData([...linksData, { id: `el-${nextExtLinkId++}`, link: "", name: "", add_to_template: props.templateId, icon: null, notInDB: true }])
    setAddMoreLinks(true)
    setLinksDataFocusElem(`link_${linksData.length}`)
  }

  function handleDelMoreLinks(e, delIdx) {
    e.preventDefault()
    axios
      .delete(process.env.GATSBY_REACT_APP_API_HOST + `/tool-links/${linksData[delIdx].id}/`, { headers: DefaultHeader })
      .then((res) => {
        setLinksData(linksData.filter((n, i) => delIdx !== i))
      })
      .catch((err) => {
        console.log(err)
      })
  }

  //function showLinksData() {
  {
    /*<Col className='px-0 border_on_hover pl-0' style={{backgroundColor:'#303C54',boxShadow:'0px 3px 6px #00000029',borderRadius:'9px',border:'1px solid #303C54', marginBottom: '12px', marginTop: '12px'}}>
      <InputGroup className="py-0 d-block " style={{borderRadius:'9px', border: '1px solid transparent'}}>
        <InputGroup.Prepend  className='border-0' style={{backgroundColor:'#303C54',borderRadius:'9px 0px 0px 9px'}}>
          <InputGroup.Text  className='border-0 d-flex justify-content-center pb-0 mb-2' style={{backgroundColor:'#303C54',borderRadius:'9px 0px 0px 9px',color:'#E0E2E5',opacity:"41%",fontSize:'16px'}} id="basic-addon1" >{'Tools & Files to be downloaded'}</InputGroup.Text>
        </InputGroup.Prepend>
    */
  }

  return (
    <>
      <div
        className={`mx-2`}
        style={{ maxHeight: "350px", overflowY: "scroll", width: "98%", borderTop: "1px solid #929BBD" }}
        id="divExtLinks"
        onScroll={() => {
          getNextExtLinksOnScroll()
        }}
      >
        {linksData.map((n, index) => {
          let clBorder = n?.error && n.error != "" ? "mt-0 border-danger" : "mt-2"
          return (
            <>
              <div key={`extLinks_${n.id}`}>
                {n.error && n.error !== "" ? (
                  <div key={`linkMsg_${n.id}`} id={`linkMsg_${index}`} className="d-inline-flex px-3 py-0">
                    <FontAwesomeIcon className="mt-2 mr-1" icon={faExclamationTriangle} color="red" size="sm" style={{ height: "14px" }} />
                    <p className="mb-0 pt-1 text-danger" style={{ fontSize: "14px" }}>
                      {n.error}
                    </p>
                  </div>
                ) : (
                  ""
                )}
                <InputGroup
                  className={`d-flex mx-2 mb-2 ${clBorder}`}
                  style={{ borderRadius: "9px", border: "1px solid transparent", backgroundColor: "#42506C", width: "99%" }}
                >
                  <InputGroup.Prepend>
                    {n.icon !== null ? (
                      <img src={n.icon} className="m-0 mt-1 ml-1" style={{ width: "25px", objectFit: "contain" }} />
                    ) : (
                      <FontAwesomeIcon className="m-0 mt-2 ml-1" icon={faLink} color="#fff" size="1x" style={{ width: "25px", objectFit: "contain" }} />
                    )}
                  </InputGroup.Prepend>
                  <FormControl
                    className={`border-0 d-inline-flex shadow-none text-white`}
                    style={{ backgroundColor: "#42506C", borderRadius: "0px", fontSize: "16px" }}
                    autoComplete="off"
                    type="text"
                    name="link"
                    defaultValue={n.link}
                    onChange={(e) => {
                      e.preventDefault()
                      if (e.target.value !== "") {
                        setAllLinksDataLoading(true)
                        setLinksDataLoading((s) => {
                          let v = { ...s }
                          v[`l_${n.id}`] = true
                          return v
                        })
                        delayedSaveExtLink(index, e.target.value, linksData)
                        setLinksDataLoading((s) => {
                          let v = { ...s }
                          v[`l_${n.id}`] = false
                          return v
                        })
                        setAllLinksDataLoading(false)
                      }
                    }}
                    onBlur={(e) => {
                      e.preventDefault()
                      if (e.target.value !== "") {
                        setAllLinksDataLoading(true)
                        setLinksDataLoading((s) => {
                          let v = { ...s }
                          v[`l_${n.id}`] = true
                          return v
                        })
                        handleLinkSave(index, e.target.value, linksData)
                        setLinksDataLoading((s) => {
                          let v = { ...s }
                          v[`l_${n.id}`] = false
                          return v
                        })
                        setAllLinksDataLoading(false)
                      }
                    }}
                    id={`link_${index}`}
                    key={`keyLink_${n.id}`}
                    placeholder="Add Link"
                    disabled={n["notInDB"] ? false : true}
                  />
                  <InputGroup.Append className={`mx-1`}>
                    {n["notInDB"] && linksDataLoading[`l_${n.id}`] === false ? (
                      <InputGroup.Text className={`bg-transparent border-0`} id={`basic-addon`}>
                        <Spinner animation="border" variant="danger" size="sm" />
                      </InputGroup.Text>
                    ) : (
                      ""
                    )}
                    {!n["notInDB"] ? (
                      <div
                        className={`border-0 rounded-circle`}
                        style={{ backgroundColor: "#303C54", height: "75%", padding: "0px 6px 1px 6px", margin: "6px 0" }}
                        onClick={(e) => {
                          handleDelMoreLinks(e, index)
                        }}
                      >
                        <FontAwesomeIcon className={`mb-0`} icon={faTrash} color="#fff" size="1x" style={{ height: "12px", objectFit: "contain" }} />
                      </div>
                    ) : (
                      ""
                    )}
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </>
          )
        })}
      </div>
      <div className={`pl-3 p-2 mt-1`}>
        {linksDataNextURL !== null ? (
          <>
            <u
              className="pointer"
              style={{ fontSize: "14px", color: "#E0E2E5" }}
              onClick={(e) => {
                fetchExtLinksNext(e)
              }}
            >
              Show More
            </u>
            <span>&nbsp;&nbsp;</span>
          </>
        ) : (
          ""
        )}
        {addMoreLinks ? (
          <OverlayTrigger key={`top`} placement={`top`} overlay={<Tooltip>Please add a link in already enabled</Tooltip>}>
            <u className="pointer" style={{ fontSize: "14px", color: "#E0E2E5" }}>
              Add More
            </u>
          </OverlayTrigger>
        ) : (
          <u
            className="pointer"
            style={{ fontSize: "14px", color: "#E0E2E5" }}
            onClick={(e) => {
              handleAddMoreLinks(e)
            }}
            disabled={addMoreLinks}
          >
            Add More
          </u>
        )}
        <p className="mb-0 mt-1 float-right mr-3" style={{ fontSize: "14px" }}>
          {allLinksDataLoading == true ? (
            <span className={`bg-transparent border-0`} id={`basic-addon`}>
              <Spinner animation="border" variant="danger" size="sm" />
            </span>
          ) : (
            <FontAwesomeIcon icon={faCheck} className="ml-3 mr-1" />
          )}
          All changes are saved
        </p>
      </div>
    </>
  )
  //}

  /*return(
        {showLinksData()}
  )*/
}

export default ExternalLinksToDownload
